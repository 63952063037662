/* Information.css */

/* Container style */
#information {
    display: flex;
    background-color: #adadad;
    flex-wrap: wrap; /* Allow content to wrap on smaller screens */
    text-align: center;
  }
  /* Heading style */
  h2 {
    color: yellow;
    font-family: 'Times New Roman', Times, serif;
    font-size: 70px;
    margin-bottom: 10px;
  }

  h3{
    color: yellow;
    font-family: 'Times New Roman', Times, serif;
    font-size: 55px;
  }
  
  /* Paragraph style */
  p {
    color: black;
    font-size: 25px;
    margin-bottom: 15px;
    padding: 0 50px; /* Increase the padding from the sides */
    font-family: 'Times New Roman', Times, serif;
}
  
  /* List style */
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  /* List item style */
  li {
    color: #333;
    font-size: 16px;
    margin-bottom: 5px;
  }
  
  img {
   
        width: 75%; /* Adjust width as needed */
        height: 50%; /* Adjust height as needed */
        overflow: hidden; /* Ensure the image stays within the container */
    
  }

  /* List item bullet style */
  li:before {
    content: "•";
    color: #007bff;
    font-size: 14px;
    margin-right: 5px;
  }
  
  /* Image style */
  .information-image {
    flex: 1;
    max-width: 50%; /* Ensure image doesn't exceed container width */
  }
  
  ul {
    list-style-type: none; /* Remove bullet points */
    padding: 0;
    margin: 0 auto;
    max-width: 900px;
    text-align: left;
}
li {
    margin-bottom: 10px;
    font-size: 18px; /* Adjust the font size as needed */
    line-height: 1.5; /* Adjust line height as needed */
    color: black;
}
li strong {
    color: yellow; /* Change the color of the strong parts */
    font-size: 18px; /* Adjust the font size as needed */
}