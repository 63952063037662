#insights {
    color: black;
    background-color: #adadad;
}

#insights h2{
    margin: 0;
    font-size: 40px;
}

#insights p{
    color: black;
    font-family: 'Times New Roman', Times, serif;
}