.video-container {
    margin: 20px auto; /* Set left and right margin to auto to center horizontally */
    max-width: 100%;
    width: 80%; /* Adjust width as needed */
    height: auto; /* Let the height adjust automatically */
  }
  
  video {
    display: block;
    margin: 0 auto; /* Set left and right margin to auto to center horizontally */
    width: 100%; /* Adjust width as needed */
    height: auto; /* Let the height adjust automatically */
    outline: none; /* Remove outline when video is focused */
  }
  