/* Add a black background color to the top navigation */
.topnav {
    background-color: black;
    overflow: hidden;
    width: 100%;
    font-family: 'Times New Roman', Times, serif;
    height: 80px; /* Increased height to 80px */
    display: flex;
    align-items: center; /* Center vertically */

  }
  
  /* Style the links inside the navigation bar */
  .topnav a {
    color: #f2f2f2;
    text-align: center;
    padding: 80px 16px; /* Adjusted padding to maintain height */
    text-decoration: none;
    font-size: 20px;
 
  }
  
  /* Change the color of links on hover */
  .topnav a:hover {
    background-color: #ddd;
    color: black;
  }
  
  /* Add a color to the active/current link */
  .topnav a.active {
    background-color: #fff004;
    color: black;
  }
  
  /* Right-aligned section inside the top navigation */
  .topnav-right {
    margin-left: auto;

  }
  
  