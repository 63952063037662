#SocialPage{
    background-color: #adadad; /* Grey background color */
    margin-top: 0;
}


#social {
    background-color: #adadad; /* Grey background color */
    padding: 20px; /* Add padding for spacing */
    display: flex; /* Use flexbox to align items */
    justify-content: center; /* Center items horizontally */
    align-items: center; /* Center items vertically */
    flex-wrap: wrap; /* Allow items to wrap to the next line if needed */
  }
  
  #social img {
    width: 45%; /* Set width */
    height: auto; /* Maintain aspect ratio */
    margin-bottom: 20px; /* Add spacing between images */
    margin-left: 20px; /* Add left margin */
  }

  