body {
  text-align: center; /* Center align all content within the body */
}

.carousel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-container {
  position: relative;
  display: inline-block;
}

.image-container img {
  max-width: 100%;
  height: auto;
  margin: 0 auto; /* Center the image horizontally */
}

button {
  margin: 10px;
  padding: 10px 20px;
  background-color: yellow;
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.button-container {
  margin-top: 10px; /* Add margin to separate the buttons from the image */
  display: flex; /* Use flexbox to align buttons */
}